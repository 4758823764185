import React, { useEffect } from "react";
import axios from "axios";
import * as urls from '../../Constants/URLs'

/**This is used for refreshin the token when it expires. */
const AttachHeaders = Wrapped => {
    function RefreshTokens(props) {
        useEffect(() => {
            axios.interceptors.request.use((req) => {
                if (req.url !== urls.RESET_PASSWORD) {
                    req.withCredentials = true
                }
                if (req.url.toString().includes("https://bpcl-stage-documents.s3.ap-south-1.amazonaws.com")) {
                    req.withCredentials = false
                }
                return req
            })
            axios.interceptors.response.use(response => {
                return response;
            }, err => {
                return new Promise((resolve, reject) => {
                    const originalReq = err.config;
                    if (err.response?.status === 401 &&
                        originalReq.url === urls.REFRESH_TOKEN) {
                        //force logout to avoid infinite loops
                        localStorage.removeItem("loggedIn");
                        localStorage.removeItem('timerStart')
                        window.location.reload();
                        localStorage.setItem('sessionTimedOut', true)
                        //console.log("sessionTimedOut AttachHeaders.Js 30 XYZ", err)
                        return Promise.reject(err);
                    } else if (err.response?.status === 401 && err.config && !err.config.__isRetryRequest && !err.config._retry) {
                        originalReq._retry = true;
                        let res = axios.get(urls.REFRESH_TOKEN)
                            .then(res => {
                                return axios(originalReq);
                            })
                            .catch(e => {
                                //force logout to avoid infinite loops
                                localStorage.removeItem("loggedIn");
                                localStorage.removeItem('timerStart')
                                window.location.reload();
                                localStorage.setItem('sessionTimedOut', true)
                                //console.log("sessionTimedOut AttachHeaders.Js 43 XYZ", err)
                            });
                        resolve(res);
                    }
                    else {
                        return reject(err);
                    }

                    return reject(err);
                });
            });
        })
        return (
            <Wrapped {...props} />
        )
    }
    return RefreshTokens
}

export default AttachHeaders