/* eslint-disable no-loop-func */
/* eslint-disable no-undef */
import React, { Suspense, lazy } from "react";
import classes from "./App.module.css";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Layout from "./Components/Layout/Layout";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Assets/fonts/fontawesome/css/all.min.css";
import "./Assets/css/app.css";
import * as actions from "./ReduxStore/actions/index";
import * as Routes from "./Constants/Routes";
import { superAdmin, admin, researcher, globalAdmin } from "./Constants/Roles";
import AttachHeaders from "./Components/Util/AttachHeaders";
import axios from "axios";
import { USER_DETAILS } from "./Constants/URLs";
import { alertMessages } from "./Constants/ErrorCodes";
import infoDialogSevice from "./Components/Util/InformationDialogs/infoDialogSevice";
import { getHelpDoc } from "./Components/Auth/Utilities/Utilities";

const AddOrganisation = lazy(() =>
  import("./Components/BPCL_Admin/Accounts/AddOrganization/AddOrganization")
);
const AdminTab = lazy(() =>
  import("./Components/Organizational_Admins/AdminTab/AdminTab")
);
const AddSuperAdmin = lazy(() =>
  import("./Components/BPCL_Admin/Accounts/AddAdmin/AddSuperAdmin")
);
const AddIp = lazy(() =>
  import("./Components/Organizational_Admins/IpManagement/AddIp")
);
const AddResearcher = lazy(() =>
  import("./Components/Organizational_Admins/AddResearcher/AddResearcher")
);
const EditHits = lazy(() =>
  import("./Components/Organizational_Admins/Subscription/EditHits")
);
const ProfileTab = lazy(() => import("./Components/Profile/ProfileTab"));
const RenewSubscription = lazy(() =>
  import("./Components/Organizational_Admins/Subscription/RenewSubscription")
);
const EditSubscription = lazy(() =>
  import("./Components/Organizational_Admins/Subscription/EditSubscription")
);
const Auth = lazy(() => import("./Components/Auth/Auth"));
const BpclAdminAccount = lazy(() =>
  import("./Components/BPCL_Admin/Accounts/Accounts")
);
const AddAdmin = lazy(() =>
  import("./Components/BPCL_Admin/Accounts/AddAdmin/AddAdmin")
);
const Module1A = lazy(() =>
  import("./Components/CoreModule/Module1A/Module1A")
);
const Module1B = lazy(() =>
  import("./Components/CoreModule/Module1B/Module1B")
);
const Module2A = lazy(() =>
  import("./Components/CoreModule/Module2A/Module2A")
);
const Module2B = lazy(() =>
  import("./Components/CoreModule/Module2B/Module2B")
);
const Module3A = lazy(() =>
  import("./Components/CoreModule/Module3A/Module3A")
);
const Module3B = lazy(() =>
  import("./Components/CoreModule/Module3B/Module3B")
);
const Module4 = lazy(() =>
  import("./Components/CoreModule/Module4/Module4")
);
const MasterCrudeOil = lazy(() =>
  import("./Components/CoreModule/MasterCrudeOil/MasterCrudeOil")
);
const CrudeOilDatabase = lazy(() =>
  import("./Components/Organizational_Admins/CrudeOils/CrudeOilDatabase")
);
const ResearcherMasterCrude = lazy(() =>
  import("./Components/CoreModule/ResearcherMasterCrude/ResearcherMasterCrude")
);
const ReportPage = lazy(() =>
  import("./Components/CoreModule/ReportPage/ReportPage")
);
const Logout = lazy(() => import("./Components/Auth/Logout/Logout"));
const ForgotPassword = lazy(() =>
  import("./Components/Auth/ForgotPassword/ForgotPassword")
);
const UpdatePassword = lazy(() =>
  import("./Components/Auth/UpdatePassword/UpdatePassword")
);
const ResetPassword = lazy(() =>
  import("./Components/Auth/ResetPassword/ResetPassword")
);

const ModuleSelection = lazy(() =>
  import("./Components/CoreModule/ModuleSelection")
);

const History = lazy(() => import("./Components/Profile/History"));

function App(props) {
  const dispatch = useDispatch();
  let isLoggedIn = false;

  var lastTouch = new Date().getTime();
  /**if these events doesnt occur in 5mins, we consider user is inactive and log him out */
  var time,
    events = ["load", "mousemove", "mousedown", "click", "scroll", "keypress"];
  let { isAuthenticated, role, organisationId } = useSelector((state) => {
    return {
      isAuthenticated: state.auth.isAuthenticated,
      role: state.auth.userRole,
      organisationId: state.auth.orgId,
    };
  });

  /**Adding all the events listener */
  for (var i in events) {
    window.addEventListener(events[i], () => {
      lastTouch = new Date().getTime();
    });
  }

  if (
    isAuthenticated &&
    localStorage.getItem("loggedIn") === "true" &&
    localStorage.getItem("timerStart") !== "true"
  ) {
    localStorage.setItem("timerStart", true);
    lastTouch = new Date().getTime();
    //console.log("setting timer");
    time = setInterval(() => {
      //console.log(new Date().getTime() - lastTouch);
      if (
        new Date().getTime() - lastTouch > 30 * 60 * 1000 &&
        localStorage.getItem("loggedIn") === "true"
      ) {
        let action = actions.authLogout((route) => {
          props.history.push(route);
        });
        dispatch(action);
        localStorage.setItem("sessionTimedOut", true);
        //console.log("sessionTimedOut App.Js 140 XYZ"); // TODO: remove all XYZ consoles
      }
    }, 5 * 1000);
  } else if (localStorage.getItem("sessionTimedOut") === "true") {
    infoDialogSevice.showPopUp(
      alertMessages.loginAgain.title,
      alertMessages.loginAgain.desc
    );
    localStorage.removeItem("sessionTimedOut");
    //console.log("sessionTimedOut App.Js 146 XYZ");
  }
  if (!isAuthenticated || localStorage.getItem("loggedIn") !== "true") {
    //console.log("timer cleared XYZ");
    clearInterval(time);
  }

  /**This is used to login/logout user from all open tabs*/
  window.addEventListener("storage", (e) => {
    clearInterval(time);
    if (e.key === "loggedIn" && e.newValue === null) {
      let action = actions.authLogout((route) => {
        props.history.push(route);
      });
      dispatch(action);
    } else if (e.key === "loggedIn" && e.newValue !== e.oldValue) {
      clearInterval(time);
      getUserData();
    }
  });

  function getUserData() {
    localStorage.removeItem("timerStart");
    isLoggedIn = true;
    axios
      .get(USER_DETAILS, { withCredentials: true })
      .then((response) => {
        let role = researcher;
        if (response.data.data.roles.length > 0) {
          role = response["data"]["data"]["roles"][0]["role"];
        }
        const action = actions.keepLoggedIn(
          role,
          response.data.data.userId,
          response.data.data.organisationId,
          response.data.data.userName
        );
        dispatch(action);
      })
      .catch((e) => {
        console.log("error");
        localStorage.clear();
        window.location.reload(); //used to log out the user
      });
  }

  window.onhelp = function () {
    return false;
  };
  window.onkeydown = (evt) => {
    switch (evt.keyCode) {
      case 112:
        //F1
        getHelpDoc(role);
        break;
      default:
        return true;
    }
    //Returning false overrides default browser event
    return false;
  };

  if (isAuthenticated) {
    isLoggedIn = true;
  } else if (!isAuthenticated) {
    if (localStorage.getItem("loggedIn") === "true") {
      getUserData();
    }
  }
  let routes = null;
  if (!isLoggedIn) {
    routes = (
      <Switch>
        <Route exact path={Routes.root} component={Auth} />
        <Route path={Routes.forgotPassword} component={ForgotPassword} />
        <Route path={Routes.resetPassword} component={ResetPassword} />
        <Redirect to={Routes.root} />
      </Switch>
    );
    return (
      <div className={classes.App}>
        <Suspense fallback={<p className="text-center mt-20">Loading ...</p>}>
          {routes}
        </Suspense>
      </div>
    );
  }
  if (isLoggedIn && role !== null) {
    if (role === superAdmin) {
      routes = (
        <Switch>
          <Route
            path={Routes.updatePassword}
            render={(props) => <UpdatePassword {...props} />}
          />
          <Route
            path={Routes.account}
            render={(props) => <BpclAdminAccount {...props} />}
          />
          <Route
            path={Routes.addAdmin}
            render={(props) => <AddAdmin {...props} />}
          />
          <Route
            path={Routes.addResearcher}
            render={(props) => <AddResearcher {...props} />}
          />
          <Route
            path={Routes.addSuperAdmin}
            render={(props) => <AddSuperAdmin {...props} />}
          />
          <Route
            path={Routes.addOrganization}
            render={(props) => <AddOrganisation {...props} />}
          />
          <Route
            path={Routes.profileTab}
            render={(props) => <ProfileTab {...props} />}
          />
          <Route
            path={Routes.logout}
            render={(props) => <Logout {...props} />}
          />
          <Route
            path={Routes.admin}
            render={(props) => <AdminTab {...props} />}
          />
          <Route path={Routes.addIp} render={(props) => <AddIp {...props} />} />
          <Route
            path={Routes.editHits}
            render={(props) => <EditHits {...props} />}
          />
          <Route
            path={Routes.editSubscription}
            render={(props) => <EditSubscription {...props} />}
          />
          <Route
            path={Routes.renewSubscription}
            render={(props) => <RenewSubscription {...props} />}
          />
          <Redirect to={Routes.account} />
        </Switch>
      );
    } else if (role === globalAdmin) {
      routes = (
        <Switch>
          <Route
            path={Routes.updatePassword}
            render={(props) => <UpdatePassword {...props} />}
          />
          <Route
            path={Routes.account}
            render={(props) => <BpclAdminAccount {...props} />}
          />
          <Route
            path={Routes.addAdmin}
            render={(props) => <AddAdmin {...props} />}
          />
          <Route
            path={Routes.addResearcher}
            render={(props) => <AddResearcher {...props} />}
          />
          <Route
            path={Routes.addSuperAdmin}
            render={(props) => <AddSuperAdmin {...props} />}
          />
          <Route
            path={Routes.addOrganization}
            render={(props) => <AddOrganisation {...props} />}
          />
          <Route
            path={Routes.profileTab}
            render={(props) => <ProfileTab {...props} />}
          />
          <Route
            path={Routes.logout}
            render={(props) => <Logout {...props} />}
          />
          <Route
            path={Routes.admin}
            render={(props) => <AdminTab {...props} />}
          />
          <Route path={Routes.addIp} render={(props) => <AddIp {...props} />} />
          <Route
            path={Routes.editHits}
            render={(props) => <EditHits {...props} />}
          />
          <Route
            path={Routes.editSubscription}
            render={(props) => <EditSubscription {...props} />}
          />
          <Route
            path={Routes.renewSubscription}
            render={(props) => <RenewSubscription {...props} />}
          />
          <Redirect to={Routes.account} />
        </Switch>
      );
    } else if (role === admin) {
      routes = (
        <Switch>
          <Route
            path={Routes.updatePassword}
            render={(props) => <UpdatePassword {...props} />}
          />
          <Route
            path={Routes.addAdmin}
            render={(props) => <AddAdmin {...props} />}
          />
          <Route
            path={Routes.crudeOilsAdminMaster}
            render={(props) => <MasterCrudeOil {...props} />}
          />
          <Route
            path={Routes.crudeOilDatabase}
            render={(props) => <CrudeOilDatabase {...props} />}
          />
          <Route
            path={Routes.addResearcher}
            render={(props) => <AddResearcher {...props} />}
          />
          <Route
            path={Routes.profileTab}
            render={(props) => <ProfileTab {...props} />}
          />
          <Route
            path={Routes.logout}
            render={(props) => <Logout {...props} />}
          />
          <Route
            path={Routes.admin}
            render={(props) => <AdminTab {...props} />}
          />
          <Route
            path={Routes.report}
            render={(props) => <ReportPage {...props} />}
          />
          <Route
            path={Routes.history}
            render={(props) => <History {...props} />}
          />
          <Route
            path={Routes.editHits}
            render={(props) => <EditHits {...props} />}
          />
          <Redirect
            to={{
              pathname: Routes.admin,
              state: {
                organisationId: organisationId,
              },
            }}
          />
        </Switch>
      );
    } else if (role === researcher) {
      routes = (
        <Switch>
          <Route
            path={Routes.module1A}
            render={(props) => <Module1A {...props} />}
          />
          <Route
            path={Routes.module1B}
            render={(props) => <Module1B {...props} />}
          />
          <Route
            path={Routes.module2A}
            render={(props) => <Module2A {...props} />}
          />
          <Route
            path={Routes.module2B}
            render={(props) => <Module2B {...props} />}
          />
          <Route
            path={Routes.module3A}
            render={(props) => <Module3A {...props} />}
          />
          <Route
            path={Routes.module3B}
            render={(props) => <Module3B {...props} />}
          />
          <Route
            path={Routes.module4}
            render={(props) => <Module4 {...props} />}
          />
          <Route
            path={Routes.crudeOilsResearcherMaster}
            render={(props) => <ResearcherMasterCrude {...props} />}
          />
          <Route
            path={Routes.updatePassword}
            render={(props) => <UpdatePassword {...props} />}
          />
          <Route
            path={Routes.profileTab}
            render={(props) => <ProfileTab {...props} />}
          />
          <Route
            path={Routes.logout}
            render={(props) => <Logout {...props} />}
          />
          <Route
            path={Routes.report}
            render={(props) => <ReportPage {...props} />}
          />
          <Route
            path={Routes.history}
            render={(props) => <History {...props} />}
          />
          <Route
            path={Routes.overview}
            render={(props) => <ModuleSelection {...props} />}
          />
          <Redirect to={Routes.overview} />
        </Switch>
      );
    }
  }

  return (
    <div className={classes.App}>
      <Layout>
        <Suspense fallback={<p className="text-center mt-20">Loading ...</p>}>
          {routes}
        </Suspense>
      </Layout>
    </div>
  );
}

export default AttachHeaders(withRouter(App));
