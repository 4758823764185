import * as actionTypes from "./actionTypes";
import axios from "axios";
import * as urls from "../../Constants/URLs";
import * as roles from "../../Constants/Roles";
import * as routes from "../../Constants/Routes";
import { errorCodes } from "../../Constants/ErrorCodes";

export const PasswordExpired = "PASSWORD_EXPIRED"
export const authStart = () => {
  return {
    type: actionTypes.AUTH_START
  };
};

export const keepLoggedIn = (userRole, userId, orgId, userName) => {
  return {
    type: actionTypes.KEEP_LOGGED_IN,
    isAuthenticated: true,
    userRole: userRole,
    activeUserId: userId,
    orgId: orgId,
    userName: userName
  };
};
export const authSuccess = (userRole, firstLogin, userId, orgId, userName) => {
  localStorage.setItem('orgId',orgId)
  return {
    type: actionTypes.AUTH_SUCCESS,
    isAuthenticated: true,
    userRole: userRole,
    activeUserId: userId,
    orgId: orgId,
    firstLogin: firstLogin,
    userName: userName
  };
};
export const updateProfile = (userName) => {
  return {
    type: actionTypes.UPDATE_PROFILE,
    userName: userName
  };
};

export const updateProfilePic = (toggleValue) => {
  return {
    type: actionTypes.PROFILE_PIC_CHANGE,
    profilePicChanged: toggleValue
  };
};
export const isIpAdded = (value) => {
  return {
    type: actionTypes.IP_ADDED,
    ipAdded: value
  };
};
export const authFail = error => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error
  };
};
export const nullifyError = () => {
  return {
    type: actionTypes.NULLIFY_ERROR
  }
}

export const authLogoutSuccess = () => {
  return {
    type: actionTypes.AUTH_LOGOUT
  };
};

export const authLogout = (authCallback) => {
  return dispatch => {
    axios
      .get(urls.LOGOUT_URL)
      .then(response => {
        if (response.data.success) {
          localStorage.removeItem('orgId')
          localStorage.removeItem("loggedIn");
          localStorage.removeItem('timerStart')
          dispatch(authLogoutSuccess());
          authCallback(routes.root)
          //console.log("logout authAction.Js 83 XYZ")
        } else {
          localStorage.removeItem('orgId')
          localStorage.removeItem("loggedIn");
          localStorage.removeItem('timerStart')
          window.location.reload(); //force logout
          //console.log("logout authAction.Js 88 XYZ")
        }
      })
      .catch(error => {
        localStorage.removeItem('loggedIn');
        localStorage.removeItem('timerStart')
        window.location.reload(); //force logout
        //console.log("logout authAction.Js 95 XYZ")
      });
  };
};

export const auth = (email, password, authCallback) => {
  return dispatch => {
    dispatch(authStart());

    let url = urls.LOGIN_URL;

    const authData = {
      userEmail: email?.toLowerCase(),
      password: password
    };
    axios
      .post(url, authData)
      .then(response => {
        if (response.data.success) {
          let role = roles.researcher;
          if (response.data.data.roles.length > 0) {
            role = response["data"]["data"]["roles"][0]["role"];
          }
          let path = routes.root;
          let firstLogin = response.data.data.firstLogin
          let userName = response.data.data.userName
          switch (role) {
            case roles.superAdmin:
              path = routes.account;
              break;
            case roles.globalAdmin:
              path = routes.account;
              break;
            case roles.admin:
              path = routes.admin;
              break;
            case roles.researcher:
              path = routes.overview;
              break;
            default:
              break;
          }
          localStorage.setItem("loggedIn", true)
          dispatch(
            authSuccess(role, firstLogin, response.data.data.userId, response.data.data.organisationId, userName)
          );
          if (firstLogin) {
            path = routes.profileTab
          }
          if (authCallback) {
            if (firstLogin) {
              path = {
                pathname: path,
                state: {
                  userId: response.data.data.userId,
                  userRole: role,
                  activeTab: 2
                }
              }
            }
            authCallback(path);
          }
          if (response.data.data?.passwordExpire) {
            let passwordExpiryMessage = `Your password will get expired in ${response.data.data.passwordExpire.toString()} days`
            setTimeout(() => {
              dispatch(setReminder(passwordExpiryMessage))
            }, 20);
          }

          setTimeout(() => {
            //
            if (response.data.data?.roles[0]?.role === roles.admin) {
              axios
                .get(urls.GET_SUBSCRIPTION + "?organisationId=" + response.data.data.organisationId)
                .then(res => {
                  let dateDifference = (Date.parse(res.data.data.licences[0].endDate) - Date.parse(new Date())) / (1000 * 60 * 60 * 24)
                  if (dateDifference >= 0 && dateDifference <= 10) {
                    let liscenceExpiryMessage = `Your liscence will get expired in ${dateDifference.toString()} days`
                    dispatch(setReminder(liscenceExpiryMessage))
                  }
                })
            } else if (response.data.data?.roles[0]?.role !== roles.superAdmin) {
              axios
                .get(urls.USER_LICENCE + "?userId=" + response.data.data.userId)
                .then(res => {
                  let dateDifference = (Date.parse(res.data.data[0].endDate) - Date.parse(new Date())) / (1000 * 60 * 60 * 24)
                  if (dateDifference >= 0 && dateDifference <= 10) {
                    let liscenceExpiryMessage = `Your liscence will get expired in ${dateDifference.toString()} days`
                    dispatch(setReminder(liscenceExpiryMessage))
                  }
                }).catch(() => {

                })
            }
          }, 3000)

        } else if (response.data.customErrorCode !== null) {
          let errorMessage
          if (response.data.customErrorCode === 'PASSWORD_EXPIRED') {
            dispatch(authFail(PasswordExpired))
          } else if (response.data.customErrorCode === 'ACOUNT_LOCKED' || response.data.customErrorCode === 'INVALID_USER_CREDENTIALS') {
            errorMessage = response.data.message
          } else {
            errorMessage = errorCodes[response.data.customErrorCode] ? errorCodes[response.data.customErrorCode] : response.data.message
          }
          dispatch(authFail(errorMessage));
        }
      })
      .catch(error => {
        dispatch(authFail(null))
      });
  };
};

export const setAuthRedirect = path => {
  return {
    type: actionTypes.SET_AUTH_REDIRECT,
    path: path
  };
};

export const checkAuthTimeout = (expirationTime) => {
  return dispatch => {
    setTimeout(() => {
      dispatch(authLogout())
    }, expirationTime);// don't forget to multiply 1000
  }
}

export const setReminder = (reminderMessage) => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_REMINDER,
      reminderMessage: reminderMessage
    })
  }
}