import React, { Component } from 'react'
import { Modal, ModalBody, Col } from 'react-bootstrap'
import { render } from 'react-dom';
/**using this mainly for showing session timed out error in this app.change props accordingly while using in any other scenarios */
class InformationDialog extends Component {
    static create() {
        const containerElement = document.createElement('div');
        document.body.appendChild(containerElement);
        return render(<InformationDialog />, containerElement);
    }

    constructor() {
        super();

        this.state = {
            isOpen: false,
            title: "Info Message",
            message: "Info Message"
        };

        this.handleCancel = this.handleCancel.bind(this);
    }

    handleCancel() {
        this.setState({ isOpen: false });
        localStorage.removeItem('sessionTimedOut')
    }

    showPopUp(title, message) {
        this.setState({ isOpen: true, title: title, message: message });
    }

    render() {
        const { isOpen, title, message } = this.state;
        return (
            <Modal show={isOpen} centered onHide={() => { this.handleCancel() }}>
                <ModalBody>
                    <div>
                        <i aria-hidden="true" className="fas fa-exclamation-triangle fa-lg"></i>
                        <span className="text-xl ml-10 mt-30 mr-30">{title}</span>
                    </div>
                    <div className="mt-10 ml-30">
                        <span className="text-md">{message}</span>
                    </div>
                    <Col md={12} lg={12} className="mt-30 text-center">
                        <button className="btn-primary" type="button" onClick={this.handleCancel}>Ok</button>
                    </Col>
                </ModalBody>
            </Modal>
        )
    }
}

export default InformationDialog;
