export const AUTH_START = "AUTH_START"
export const AUTH_SUCCESS = "AUTH_SUCCESS"
export const AUTH_FAIL = "AUTH_FAIL"
export const AUTH_LOGOUT = "AUTH_LOGOUT"
export const KEEP_LOGGED_IN = "KEEP_LOGGED_IN"
export const UPDATE_PROFILE = "UPDATE_PROFILE"
export const UPDATE_TOKEN = "UPDATE_TOKEN"
export const PROFILE_PIC_CHANGE = "PROFILE_PIC_CHANGE"
export const IP_ADDED = "IP_ADDED"
export const SET_AUTH_REDIRECT = "SET_AUTH_REDIRECT"
export const NULLIFY_ERROR = "NULLIFY_ERROR"

export const ADD_ADMIN_START = "ADD_ADMIN_START"
export const ADD_ADMIN_SUCCESS = "ADD_ADMIN_SUCCESS"
export const ADD_ADMIN_FAIL = "ADD_ADMIN_FAIL"
export const SET_REMINDER = "SET_REMINDER"
export const UPDATE_CURRENT_ORGANISATION = "UPDATE_CURRENT_ORGANISATION"

export const UPDATE_CONSTRAINTS = "UPDATE_CONSTRAINTS" 