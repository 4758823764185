import * as actionTypes from '../actions/actionTypes';

const initialState = {
    adminList: [{
        name: 'XYZ',
        email: 'xyz@gmail.com',
        phoneNumber: '1234567890',
        country: 'India'
    }],
    organisations: {
        /*
        orgId: {
            expired: bool,
            active: bool
        }
        */
    },
    loading: false,
    error: null
}



const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_ADMIN_START:
            return {
                ...state,
                loading: true,
                error: null
            }
        case actionTypes.ADD_ADMIN_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                adminList: [
                    ...state.adminList,
                    ...action.adminList
                ]
            }
        case actionTypes.ADD_ADMIN_FAIL:
            return {
                ...state,
                loading: false,
                error: 'error occurre auth fail' // action.error
            }
        case actionTypes.UPDATE_CURRENT_ORGANISATION:
            return {
                ...state,
                organisations: {
                    ...state.organisations,
                    ...action.payload
                }
            }
        default:
            return {
                ...state
            }

    }
}

export default reducer;