import React from "react";
import classes from "./Toolbar.module.scss";
import Logo from './../Logo/Logo';
import NavigationItems from './../Navigation/NavigationItems/NavigationItems';

function Toolbar(props) {
  return (
    <header className={classes.toolbar}>
      <div className="container">
        <div className={classes.Logo}>
          <Logo />
        </div>
        <nav className={classes.DesktopOnly}>
          <NavigationItems />
        </nav>
      </div>
    </header>
  )
}

export default Toolbar;
