
// /*Internal Staging URLs: uncomment while deploying*/
// /*user microservice end points*/
// export const LOGIN_URL = "http://172.16.17.136:8085/authentication/login";
// export const LOGOUT_URL = "http://172.16.17.136:8085/authentication/logout";
// export const FORGOT_PASSWORD = "http://172.16.17.136:8085/authentication/forgot-password";
// export const RESET_PASSWORD = "http://172.16.17.136:8085/authentication/reset-password-account";
// export const UPDATE_PASSWORD = "http://172.16.17.136:8085/userprofileupdate/password";
// export const GET_SUPERADMINS = "http://172.16.17.136:8085/userprofileupdate/bpcladmins";
// export const GET_CUSTOMER_ADMINS = "http://172.16.17.136:8085/userprofileupdate/organisationAdmins";
// export const GET_RESEARCHER = "http://172.16.17.136:8085/userprofileupdate/researcherList";
// export const ADD_ADMIN = "http://172.16.17.136:8085/authentication/adminRegister";
// export const ADD_RESEARCHER = "http://172.16.17.136:8085/authentication/register";
// export const ADD_SUPER_ADMIN = "http://172.16.17.136:8085/authentication/superAdminRegister";
// export const CHANGE_USER_STATUS = "http://172.16.17.136:8085/userprofileupdate/changeStatus";
// export const GET_PROFILE = "http://172.16.17.136:8085/userprofileupdate/getProfile";
// export const GET_RESEARCHER_PROFILE = "http://172.16.17.136:8085/userprofileupdate/getReseacherProfile";
// export const UPDATE_RESEARCHER_PROFILE = "http://172.16.17.136:8085/userprofileupdate/updateProfile";
// export const DELETE_USER = "http://172.16.17.136:8085/userprofileupdate/delete";
// export const REFRESH_TOKEN = "http://172.16.17.136:8085/authentication/refresh-token";
// export const USER_DETAILS = "http://172.16.17.136:8085/userprofileupdate/myDetails";
// export const PROFILE_PICTURE = "http://172.16.17.136:8085/authentication/profilePicture";
// export const ADD_INQUIRY = "http://172.16.17.136:8085/customer/add-inquiry";

// /**Core Module end points */
// export const MODULE1A_URL = "http://172.16.17.136:8202/kmodel/m1";
// export const MODULE1B_URL = "http://172.16.17.136:8202/kmodel/m1a";
// export const MODULE2A_URL = "http://172.16.17.136:8202/kmodel/m2";
// export const MODULE2B_URL = "http://172.16.17.136:8202/kmodel/m2a";
// export const MODULE3A_URL = "http://172.16.17.136:8202/kmodel/m3";
// export const HISTORY_PAGE = "http://172.16.17.136:8202/kmodel/historylist";
// export const REPORT_PAGE = "http://172.16.17.136:8202/kmodel/history/";
// export const USER_HISTORY = "http://172.16.17.136:8202/kmodel/resercherhistorylist";
// export const TWO_BLEND = "http://172.16.17.136:8202/kmodel/twoBlend/";
// export const MODULE_3BC_CONSTRAINTS = "http://172.16.17.136:8202/kmodel/m3BCConstraints";
// export const MODULE_3A_CONSTRAINTS = "http://172.16.17.136:8202/kmodel/m3Constraints";
// export const MODULE_2_CONSTRAINTS = "http://172.16.17.136:8202/kmodel/m2Constraints";
// export const MODULE_1_CONSTRAINTS = "http://172.16.17.136:8202/kmodel/m1Constraints";
// export const S3BUCKET_URL = "http://172.16.17.136:8202/kmodel/preSignedUrl";
// export const BROCHURE_URL = "http://172.16.17.136:8202/kmodel/getBrocher";

// /**Account ms endpoints */
// export const GET_ORGANIZATIONS = "http://172.16.17.136:8089/customer/accounts";
// export const ADD_ORGANIZATIONS = "http://172.16.17.136:8089/customer/add-account";
// export const GET_IP_RANGE = "http://172.16.17.136:8089/customerNetwork/ipranges";
// export const DELETE_IP = "http://172.16.17.136:8089/customerNetwork/ip-delete";
// export const ADD_IP = "http://172.16.17.136:8089/customerNetwork/ipRange";
// export const CHANGE_ORG_STATUS = "http://172.16.17.136:8089/customer/changeStatus";
// export const DELETE_ORGANIZATION = "http://172.16.17.136:8089/customer/account";
// export const GET_ORGANIZATIONS_BY_ID = "http://172.16.17.136:8089/customer/getCustomer";
// export const EDIT_ORGANISATION = "http://172.16.17.136:8089/customer/editCustomer";

// /**Subscription end points */
// export const GET_SUBSCRIPTION = "http://172.16.17.136:8083/licence/licence";
// export const USER_LICENCE = "http://172.16.17.136:8083/licence/userlicence";
// export const CUSTOMER_LICENCE = "http://172.16.17.136:8083/licence/accountlicence";
// export const COMPOSITION_VARIATION = "http://172.16.17.136:8202/kmodel/difference";

// /**help documents */
// export const HELP_DOC = "http://172.16.17.136:8202/kmodel/getHelpManual";


// /*Staging URLs for public IP*/
// /*user microservice end points*/
// export const LOGIN_URL = "http://111.93.244.226:8085/authentication/login";
// export const LOGOUT_URL = "http://111.93.244.226:8085/authentication/logout";
// export const FORGOT_PASSWORD = "http://111.93.244.226:8085/authentication/forgot-password";
// export const RESET_PASSWORD = "http://111.93.244.226:8085/authentication/reset-password-account";
// export const UPDATE_PASSWORD = "http://111.93.244.226:8085/userprofileupdate/password";
// export const GET_SUPERADMINS = "http://111.93.244.226:8085/userprofileupdate/bpcladmins";
// export const GET_CUSTOMER_ADMINS = "http://111.93.244.226:8085/userprofileupdate/organisationAdmins";
// export const GET_RESEARCHER = "http://111.93.244.226:8085/userprofileupdate/researcherList";
// export const ADD_ADMIN = "http://111.93.244.226:8085/authentication/adminRegister";
// export const ADD_RESEARCHER = "http://111.93.244.226:8085/authentication/register";
// export const ADD_SUPER_ADMIN = "http://111.93.244.226:8085/authentication/superAdminRegister";
// export const CHANGE_USER_STATUS = "http://111.93.244.226:8085/userprofileupdate/changeStatus";
// export const GET_PROFILE = "http://111.93.244.226:8085/userprofileupdate/getProfile";
// export const GET_RESEARCHER_PROFILE = "http://111.93.244.226:8085/userprofileupdate/getReseacherProfile";
// export const UPDATE_RESEARCHER_PROFILE = "http://111.93.244.226:8085/userprofileupdate/updateProfile";
// export const DELETE_USER = "http://111.93.244.226:8085/userprofileupdate/delete";
// export const REFRESH_TOKEN = "http://111.93.244.226:8085/authentication/refresh-token";
// export const USER_DETAILS = "http://111.93.244.226:8085/userprofileupdate/myDetails";
// export const PROFILE_PICTURE = "http://111.93.244.226:8085/authentication/profilePicture";
// export const ADD_INQUIRY = "http://111.93.244.226:8085/customer/add-inquiry";

// // /**Core Module end points */
// export const MODULE1A_URL = "http://111.93.244.226:8202/kmodel/m1";
// export const MODULE1B_URL = "http://111.93.244.226:8202/kmodel/m1a";
// export const MODULE2A_URL = "http://111.93.244.226:8202/kmodel/m2";
// export const MODULE2B_URL = "http://111.93.244.226:8202/kmodel/m2a";
// export const MODULE3A_URL = "http://111.93.244.226:8202/kmodel/m3";
// export const HISTORY_PAGE = "http://111.93.244.226:8202/kmodel/historylist";
// export const REPORT_PAGE = "http://111.93.244.226:8202/kmodel/history/";
// export const USER_HISTORY = "http://111.93.244.226:8202/kmodel/resercherhistorylist";
// export const TWO_BLEND = "http://111.93.244.226:8202/kmodel/twoBlend/";
// export const MODULE_3BC_CONSTRAINTS = "http://111.93.244.226:8202/kmodel/m3BCConstraints";
// export const MODULE_3A_CONSTRAINTS = "http://111.93.244.226:8202/kmodel/m3Constraints";
// export const MODULE_2_CONSTRAINTS = "http://111.93.244.226:8202/kmodel/m2Constraints";
// export const MODULE_1_CONSTRAINTS = "http://111.93.244.226:8202/kmodel/m1Constraints";
// export const S3BUCKET_URL = "http://111.93.244.226:8202/kmodel/preSignedUrl";
// export const BROCHURE_URL = "http://111.93.244.226:8202/kmodel/getBrocher";

// // /**Account ms endpoints */
// export const GET_ORGANIZATIONS = "http://111.93.244.226:8089/customer/accounts";
// export const ADD_ORGANIZATIONS = "http://111.93.244.226:8089/customer/add-account";
// export const GET_IP_RANGE = "http://111.93.244.226:8089/customerNetwork/ipranges";
// export const DELETE_IP = "http://111.93.244.226:8089/customerNetwork/ip-delete";
// export const ADD_IP = "http://111.93.244.226:8089/customerNetwork/ipRange";
// export const CHANGE_ORG_STATUS = "http://111.93.244.226:8089/customer/changeStatus";
// export const DELETE_ORGANIZATION = "http://111.93.244.226:8089/customer/account";
// export const GET_ORGANIZATIONS_BY_ID = "http://111.93.244.226:8089/customer/getCustomer";
// export const EDIT_ORGANISATION = "http://111.93.244.226:8089/customer/editCustomer";

// // /**Subscription end points */
// export const GET_SUBSCRIPTION = "http://111.93.244.226:8083/licence/licence";
// export const USER_LICENCE = "http://111.93.244.226:8083/licence/userlicence";
// export const CUSTOMER_LICENCE = "http://111.93.244.226:8083/licence/accountlicence";
// export const COMPOSITION_VARIATION = "http://111.93.244.226:8202/kmodel/difference";

// // /**help documents */
// export const HELP_DOC = "http://111.93.244.226:8202/kmodel/getHelpManual";

/*Production URLs: uncomment while deploying on live*/
/*user microservice end points*/
export const LOGIN_URL = "https://api.bpcl.kmodel.in/authentication/login";
export const LOGOUT_URL = "https://api.bpcl.kmodel.in/authentication/logout";
export const FORGOT_PASSWORD = "https://api.bpcl.kmodel.in/authentication/forgot-password";
export const RESET_PASSWORD = "https://api.bpcl.kmodel.in/authentication/reset-password-account";
export const UPDATE_PASSWORD = "https://api.bpcl.kmodel.in/userprofileupdate/password";
export const GET_SUPERADMINS = "https://api.bpcl.kmodel.in/userprofileupdate/bpcladmins";
export const GET_CUSTOMER_ADMINS = "https://api.bpcl.kmodel.in/userprofileupdate/organisationAdmins";
export const GET_RESEARCHER = "https://api.bpcl.kmodel.in/userprofileupdate/researcherList";
export const ADD_ADMIN = "https://api.bpcl.kmodel.in/authentication/adminRegister";
export const ADD_RESEARCHER = "https://api.bpcl.kmodel.in/authentication/register";
export const ADD_SUPER_ADMIN = "https://api.bpcl.kmodel.in/authentication/superAdminRegister";
export const CHANGE_USER_STATUS = "https://api.bpcl.kmodel.in/userprofileupdate/changeStatus";
export const GET_PROFILE = "https://api.bpcl.kmodel.in/userprofileupdate/getProfile";
export const GET_RESEARCHER_PROFILE = "https://api.bpcl.kmodel.in/userprofileupdate/getReseacherProfile";
export const UPDATE_RESEARCHER_PROFILE = "https://api.bpcl.kmodel.in/userprofileupdate/updateProfile";
export const DELETE_USER = "https://api.bpcl.kmodel.in/userprofileupdate/delete";
export const USER_DETAILS = "https://api.bpcl.kmodel.in/userprofileupdate/myDetails";
export const REFRESH_TOKEN = "https://api.bpcl.kmodel.in/authentication/refresh-token";
export const PROFILE_PICTURE = "https://api.bpcl.kmodel.in/authentication/profilePicture";
export const ADD_INQUIRY = "https://api.bpcl.kmodel.in/authentication/addInquiry";

/**Core Module end points */
export const MODULE1A_URL = "https://api.bpcl.kmodel.in/kmodel/m1";
export const MODULE1B_URL = "https://api.bpcl.kmodel.in/kmodel/m1a";
export const MODULE2A_URL = "https://api.bpcl.kmodel.in/kmodel/m2";
export const MODULE2B_URL = "https://api.bpcl.kmodel.in/kmodel/m2a";
export const MODULE3A_URL = "https://api.bpcl.kmodel.in/kmodel/m3";
export const MODULE3B_URL = "https://api.bpcl.kmodel.in/kmodel/m3b";
export const MODULE4_URL = "https://api.bpcl.kmodel.in/kmodel/m4";
export const REPORT_PAGE = "https://api.bpcl.kmodel.in/kmodel/history/";
export const HISTORY_PAGE = "https://api.bpcl.kmodel.in/kmodel/historylist";
export const USER_HISTORY = "https://api.bpcl.kmodel.in/kmodel/resercherhistorylist";
export const TWO_BLEND = "https://api.bpcl.kmodel.in/kmodel/twoBlend/";
export const MODULE_4_CONSTRAINTS = "https://api.bpcl.kmodel.in/kmodel/m4/constraints";
export const MODULE_3BC_CONSTRAINTS = "https://api.bpcl.kmodel.in/kmodel/m3BCConstraints";
export const MODULE_3A_CONSTRAINTS = "https://api.bpcl.kmodel.in/kmodel/m3Constraints";
export const MODULE_2_CONSTRAINTS = "https://api.bpcl.kmodel.in/kmodel/m2Constraints";
export const MODULE_1_CONSTRAINTS = "https://api.bpcl.kmodel.in/kmodel/m1Constraints";
export const S3BUCKET_URL = "https://api.bpcl.kmodel.in/kmodel/preSignedUrl";
export const BROCHURE_URL = "https://api.bpcl.kmodel.in/kmodel/getBrocher";
export const COEFFICIENTS = "https://api.bpcl.kmodel.in/kmodel/coefficients";
export const CRUDE_OILS = "https://api.bpcl.kmodel.in/kmodel/";
export const MODULE4_INPUTS = "https://api.bpcl.kmodel.in/kmodel/m4/input";
export const DOWNLOAD_CRUDE_OILS_EXCEL = "https://api.bpcl.kmodel.in/kmodel/org/"

/**Account ms endpoints */
export const GET_ORGANIZATIONS = "https://api.bpcl.kmodel.in/customer/accounts";
export const ADD_ORGANIZATIONS = "https://api.bpcl.kmodel.in/customer/add-account";
export const GET_IP_RANGE = "https://api.bpcl.kmodel.in/customerNetwork/ipranges";
export const DELETE_IP = "https://api.bpcl.kmodel.in/customerNetwork/ip-delete";
export const ADD_IP = "https://api.bpcl.kmodel.in/customerNetwork/ipRange";
export const CHANGE_ORG_STATUS = "https://api.bpcl.kmodel.in/customer/changeStatus";
export const DELETE_ORGANIZATION = "https://api.bpcl.kmodel.in/customer/account";
export const GET_ORGANIZATIONS_BY_ID = "https://api.bpcl.kmodel.in/customer/getCustomer";
export const EDIT_ORGANISATION = "https://api.bpcl.kmodel.in/customer/editCustomer";

/**Subscription end points */
export const GET_SUBSCRIPTION = "https://api.bpcl.kmodel.in/licence/licence";
export const USER_LICENCE = "https://api.bpcl.kmodel.in/licence/userlicence";
export const CUSTOMER_LICENCE = "https://api.bpcl.kmodel.in/licence/accountlicence";
export const COMPOSITION_VARIATION = "https://api.bpcl.kmodel.in/kmodel/difference";
/**Help documents */
export const HELP_DOC = "https://api.bpcl.kmodel.in/kmodel/getHelpManual";

