
export const CHARACTERISTIC_CONSTRAINTS = {
  acidity: {
    fullName: "Acidity, mg/KOH",
    range: [0.01, 2.0],
    blendingConstraintName: ['lowCapAcidity', 'upCapAcidity'],
    shortName: "acidity"
  },
  api: {
    fullName: "API",
    range: [15, 55],
    blendingConstraintName: ['lowCapApi', 'upCapApi'],
    shortName: "api"
  },
  kv: {
    fullName: "Kinematic Viscosity, cSt @40\xB0C",
    range: [1.5, 500],
    blendingConstraintName: ['lowCapV', 'upCapV'],
    shortName: "kv"
  },
  mcr: {
    fullName: "MCR, wt%",
    range: [0, 99],
    blendingConstraintName: ['lowCapCcr', 'upCapCcr'],
    shortName: "ccr"
  },
  pourPoint: {
    fullName: "Pour Point, \xB0C",
    range: [-60, 50],
    blendingConstraintName: ['lowCapPp', 'upCapPp'],
    shortName: "pp"
  },
  sulphur: {
    fullName: "Sulphur, wt%",
    range: [0.01, 5.0],
    blendingConstraintName: ['lowCapS', 'upCapS'],
    shortName: "sul"
  },
  nitrogen: {
    fullName: "Total Nitrogen, ppmw",
    range: [100, 3000],
    blendingConstraintName: ['lowCapTotalN', 'upCapTotalN'],
    shortName: "nit"
  },
  yield: {
    fullName: "Total Distillate Yields, wt%",
    range: [55, 98],
    blendingConstraintName: ['lowCapTotalDistYields', 'upCapTotalDistYields'],
    shortName: "dy"
  }
};

export const INEQUALITY_CONSTANTS = {
  le: '<=',
  l: '<',
  e: '=',
  g: '>',
  ge: '>='
}

export const MODULE3_BLENDING_CONSTRAINTS = {
  sulphur: {
    fullName: "Sulphur, wt%",
    range: [0.001, 5],
    blendingConstraintName: ['lowCapSul', 'upCapSul'],
    shortName: "sul"
  },
  flashPoint: {
    fullName: "Flash Point, \xB0C",
    range: [0, 350],
    blendingConstraintName: ['lowCapFlashPoint', 'upCapFlashPoint'],
    shortName: "fp"
  },
  kvMin: {
    fullName: "Kinematic Viscosity - Min, cSt @50\xB0C",
    range: [1.5, 100000000],
    blendingConstraintName: ['lowCapKv', 'upCapKv'],
    shortName: "kv"
  },
  kvMax: {
    fullName: "Kinematic Viscosity - Max, cSt @50\xB0C",
    range: [1.5, 100000000],
    blendingConstraintName: ['lowCapKv', 'upCapKv'],
    shortName: "kv"
  },
  pourPoint: {
    fullName: "Pour Point, \xB0C",
    range: [-70, 100],
    blendingConstraintName: ['lowCapPourPoint', 'upCapPourPoint'],
    shortName: "pp"
  },
}

export const MODULE3_EDIT_CONSTRAINTS = {

  api: {
    fullName: "API",
    range: [0.1, 20],
    blendingConstraintName: ['lowCapApi', 'upCapApi'],
    shortName: "api"
  },
  kv50: {
    fullName: "Kinematic Viscosity, cSt @50\xB0C",
    range: [1.5, 100000000],
    blendingConstraintName: ['lowCapKv50', 'upCapKv50'],
    shortName: "kv50"
  },
  kv100: {
    fullName: "Kinematic Viscosity, cSt @100\xB0C",
    range: [5, 100000],
    blendingConstraintName: ['lowCapKv100', 'upCapkv100'],
    shortName: "kv100"
  },
  pourPoint: {
    fullName: "Pour Point \xB0C",
    range: [-90, 110],
    blendingConstraintName: ['lowCapPp', 'upCapPp'],
    shortName: "pp"
  },
  sulphur: {
    fullName: "Sulphur, wt%",
    range: [0.001, 10],
    blendingConstraintName: ['lowCapS', 'upCapS'],
    shortName: "sul"
  },
  mcr: {
    fullName: "MCR wt%",
    range: [0.001, 40],
    blendingConstraintName: ['lowCapCcr', 'upCapCcr'],
    shortName: "ccr"
  },
  flashPoint: {
    fullName: "Flash Point, \xB0C",
    range: [0, 400],
    blendingConstraintName: ['lowCapFlashPoint', 'upCapFlashPoint'],
    shortName: "fp"
  },
  acidity: {
    fullName: "Acidity, mg/KOH",
    range: [0.01, 2.0],
    blendingConstraintName: ['lowCapAcidity', 'upCapAcidity'],
    shortName: "acidity"
  }
}

export const MODULE4_CHARACTERISTIC_CONSTRAINTS = {
  api: {
    fullName: "API",
    range: [15, 55],
    blendingConstraintName: ['lowCapApi', 'upCapApi'],
    shortName: "api"
  },
  kv: {
    fullName: "Kinematic Viscosity, cSt @40\xB0C",
    range: [1.5, 500],
    blendingConstraintName: ['lowCapV', 'upCapV'],
    shortName: "kv"
  },
  mcr: {
    fullName: "MCR, wt%",
    range: [0, 99],
    blendingConstraintName: ['lowCapCcr', 'upCapCcr'],
    shortName: "ccr"
  },
  sulphur: {
    fullName: "Sulphur, wt%",
    range: [0.01, 5.0],
    blendingConstraintName: ['lowCapS', 'upCapS'],
    shortName: "sul"
  }
};

export const constraintNameMap = {
  ccr: "mcr",
  api: "api",
  sul: "sulphur",
  kv: "kv",
  pourPoint: "pourPoint",
  kvCutter: "kv50",
  kv50: "kv50",
  kv100: "kv100",
  flashPoint: "flashPoint"
}

export const getModule3BlendingConstraint = (blending) => {
  let blendingConstraints = []
  Object.keys(blending).forEach((blendingKey) => {
    let name = blending[blendingKey].name
    let sign = blending[blendingKey].sign
    blendingConstraints.push({
      name: MODULE3_BLENDING_CONSTRAINTS[name].shortName,
      sign: INEQUALITY_CONSTANTS[sign],
      value: Number(blending[blendingKey].value)
    })
  })
  return blendingConstraints
}


export const getBlendingConstraint = (blending) => {
  let blendingConstraints = []
  Object.keys(blending).forEach((blendingKey) => {
    let name = blending[blendingKey].name
    let sign = blending[blendingKey].sign
    blendingConstraints.push({
      name: CHARACTERISTIC_CONSTRAINTS[name].shortName,
      sign: INEQUALITY_CONSTANTS[sign],
      value: Number(blending[blendingKey].value)
    })
  })
  return blendingConstraints
}
