import React from 'react';
import logoImagePath from './../../Assets/Images/kmodel-logo.png';
import classes from './Logo.module.scss';

function Logo() {
    return (
        <div className={classes.Logo}>
            <img src={logoImagePath} alt="kmodel-logo" />
        </div>
    )
}

export default Logo;