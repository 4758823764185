export const errorCodes = {
    SERVER_ERROR: "Something went wrong. Please try again.",
    EMPTY_PARAMS: "Something went wrong. Please try again.",
    EMAIL_AND_PASSWORD_EMPTY: "Password and email cannot be empty",
    USERNAME_AND_PASSWORD_EMPTY: "User name and password cannot be empty",
    USERNAME_AND_EMAIL_EMPTY: "User name and email cannot be empty",
    EMPTY_EMAIL: "Email ID cannot be empty.",
    EMPTY_USERNAME: "User name cannot be empty.",
    INVALID_EMAIL_PATTERN: "Invalid email id.",
    EMAIL_ALREADY_EXIST: "Email ID is already registered",
    EMPTY_PASSWORD: "Password cannot be empty",
    INVALID_USER_CREDENTIALS: "Invalid username or password",
    EMAIL_NOT_FOUND: "Invalid email id",
    INVALID_USER_IP: "Ip is not in the valid range.",
    USER_NOT_ACTIVE: "Inactive user.",
    EMPTY_TOKEN: "You are not authorised. Please login again.",
    SAME_OLD_NEW_PASS: "Old password and new password cannot be same.",
    INVALID_TOKEN: "You are not authorised. Please login again",
    REFRESH_TOKEN_EXPIRED: "You are not authorised. Please login again",
    USER_ID_NOT_FOUND: "User not found",
    PHONE_NOT_VALID: "Please enter a valid phone number",
    DB_UPDATE_ERROR: "Failed to update data.",
    SQL_EXCEPTION: "Issue while processing your request. Please try again.",
    ACCOUNT_NOT_FOUND: "Account you are trying to edit does not exist.",
    IP_ADDRESS_INVALID: "Please enter a valid IP address.",
    PASSWORD_DO_NOT_MATCH: "Passwords do not match",
    PASSWORD_FORMAT: "Password must be atleast 8 characters with one lowercase, one uppercase, one numeric and one special character.",
    INVALID_ZIP_CODE: "Please enter a valid zip code",
    NO_COUNTS: "You have exhausted your hits for the module.",
    USER_CONSTRAINTS_OUT_OF_BOUND: "One of the given constraints is out of range.",
    PROPERTIES_OUT_OF_BOUND: "One of the crude oil properties is out of range.",
    USER_CONSTRAINTS_OUT_OF_BOUND_MODULE3: "One of the given constraints is out of range.",
    PROPERTIES_OUT_OF_BOUND_MODULE3: "One of the fuel oil properties is out of range.",
    UNAUTHORIZED: "You are not authorized to run this module",
    INVALID_VALUES: "Invalid request parameter.",
    USER_ALREADY_PRESENT: "This user email is already taken",
    INVALID_EMAIL: "Enter a valid email",
    LARGE_PHOTO: "Size of image is too large.",
    INVALID_FILE_TYPE: 'This format of file is not supported',
    FILE_NOT_PRESENT: 'File is not present.',
    REQUIRED_INPUT_FIELD: 'Required field',
    ONLY_ALPHANUMERIC_INPUT: 'Should be alphanumeric',
    ONLY_NUMERIC_INPUT: 'Only numbers allowed',
    ONLY_POSITIVE_0_100: 'Number should between 0 to 100',
    OUT_OF_RANGE: "Value is out of range",
    SHOULD_BE_POSITIVE: 'Number should be positive',
    SUMMATION_NOT_100: 'Total summation of capacities needs to be 100%',
    PRESIGNED_URL_ERROR: 'Could not download at this moment. Please try again after some time',
    COULD_NOT_LOAD: "Could not load contents at this moment",
    CANT_DECREASE_HITS: "Cannot decrease hit counts more than the available hits",
    COUNT_EXCEEDED: "Maximum user limit reached",
    INACTIVE_UPDATE: "Cannot update when inactive"
}

export const errors = {
    400: {
        meaning: "Bad Request",
        desc: "Something went wrong. Please try again."
    },
    413: {
        meaning: "Request Entity Too Large",
        desc: "Maximum allowed 50 Crude oils to store in database."
    },
    404: {
        meaning: "Page not found",
        desc: "Something went wrong. Please try again."
    },
    401: {
        meaning: "Unauthorized",
        desc: "Something went wrong. Please try again."
    },
    500: {
        meaning: "Server down",
        desc: "Something went wrong. Please try again."
    },
    503: {
        meaning: "Service unavailable",
        desc: "Something went wrong. Please try again."
    },
    default: {
        meaning: "Something went wrong",
        desc: "Something went wrong. Please try again."
    },
}

export const info = {
    oneMonthToExpire: "Your subscription will expire within a month.",
    subscriptionExpired: "Your subscription has expired.",
    maxUsersReached: "Maximum allotted Users limit reached. Please ask BPCL support to increase the number of Users in your subscription.",
    noSubscription: "Organisation does not have an active subscription.",
    reportMail: "It could take upto a couple of hours to generate the report. We will notify you on your registered email id once the report is generated.",
    noHitsAvailable: "The number of hits available to you for this module are exhausted. Please contact your admin.",
    expiredSubscription: "Subscription Expired",
    emptyHistoryResult: "No result found",
    noOptimalSolutions: "No optimal solutions",
    updatePasswordInfo: "Please update your password for enhanced security",
    passwordCriteriaInfo: "Password must have atleast one lowercase character, one uppercase character, one numeric character, one special character and atleast 8 characters",
    taglineName: "Blending for future",
    noHits: "No Hits"
}
export const confirmMessages = {
    default: {
        question: "Are you sure?",
        desc: "Please confirm your action."
    },
    orgStatusChange: {
        question: "Are you sure?",
        desc: "Changing status of the organisation will effect all its users."
    },
    organisationDelete: {
        question: "Are you sure you want to delete the organisation?",
        desc: "Organisation along with users will be deleted permanently."
    },
    logOut: {
        question: "Are you sure you want to log out?",
        desc: "Please confirm to log out of the application."
    },
    updateModuleConstraints: {
        question: "Are you sure you want to update constraints?",
        desc: "Constraints will be updated for all organisations."
    },
    updateCompositionVariation: {
        question: "Are you sure you want to update configuration?",
        desc: "It will be updated for all researchers."
    },
    confirmRunModule: {
        question: "Are you sure you want to run?",
        desc: "Hitting on run will be considered as a new blend"
    },
    passwordExpired: {
        question: "Your password is expired",
        desc: 'Please get a reset password link by clicking on "Forgot Password?"'
    }
    //please add confirmation message here,in casse its needed for changing status of researcher and other profiles. now for all other confirmation we get default messages
}

export const alertMessages = {
    statusChangeSuccess: "Successfully changed status.",
    statusChangeFailure: "Failed to change status.",
    errorOccured: "Error occured. Try again.",
    userAddedSuccessfully: "User added successfully.",
    failedToAddUser: "Failed to add user. Try again.",
    organisationAddedSuccessfully: "Organization added successfully",
    failedToLoadData: "Failed to load data.",
    constraintEditSuccess: "Edited successfully",
    updateSuccessful: "Successfully updated.",
    addIpSuccess: "IP added successfully.",
    failedToDeleteIp: "Failed to delete IP.",
    deleteIpSuccess: "Successfully deleted IP.",
    hitsAddedSuccess: "Hits added successfully.",
    subscriptionUpdateSuccess: "Subscription successfully updated.",
    subscriptionAddedSuccessfully: "Subscription added successfully.",
    profileDetailsUpdated: "Profile details updated successfully.",
    userDeletedSuccessfully: "User deleted successfully.",
    failedToDeleteUser: "Failed to delete user.",
    organisationDeletedSuccessfully: "Organisation deleted successfully.",
    failedToDeleteOrganisation: "Failed to delete organisation.",
    picUploadSuccess: "Successfully uploaded picture.",
    failedToUploadPic: "Failed to upload profile picture.",
    passwordChangedSuccessfully: "Password successfully changed.",
    picUploadFailed: "Failed to upload profile picture.",
    maxPicSizeCrossed: "Maximum allowed size of picture is 5MB.",
    emptyExcelData: "Could not find appropriate data",
    atleastTwoExcelInput: "Please fill atleast two input containers",
    unsupportedFile: "Unsupported File",
    inactiveMessage: "You were inactive for some time. Please login again.",
    sessionTimeOut: {
        title: 'Session timed out',
        desc: 'Please login again to continue.'
    },
    loginAgain: {
        title: 'Something went wrong',
        desc: 'Please login again to continue.'
    },
    zeroHits: {
        title: "You have no hits to run this module",
        desc: '"Run" button will be disabled'
    },
    maxCrudeOilSave: "Failed to save in database.",
}