import './Polyfills/Polyfill'

// import 'react-app-polyfill/ie11';
// import "react-app-polyfill/stable";

// import 'core-js/features/array/find';
// import 'core-js/features/array/includes';
// import 'core-js/features/number/is-nan';
// import "core-js/modules/es.promise";
// import "core-js/modules/es.object.assign";
// import "core-js/modules/es.object.keys";
// import 'core-js/modules/es.string.includes';
// import 'core-js/modules/es.symbol';
// import 'core-js/modules/es.symbol.async-iterator';
// import 'regenerator-runtime/runtime';
import './Polyfills/FormatToParts'

import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import authReducer from './ReduxStore/reducers/authReducer';
import adminReducer from './ReduxStore/reducers/adminReducer';
import researcherReducer from './ReduxStore/reducers/researcherReducer';

const rewritePattern = require("regexpu-core");
const {
    generateRegexpuOptions,
} = require("@babel/helper-create-regexp-features-plugin/lib/util");

const { RegExp } = global;
try {
    new RegExp("a", "u");
} catch (err) {
    // @ts-ignore
    global.RegExp = function (pattern, flags) {
        if (flags && flags.includes("u")) {
            return new RegExp(
                rewritePattern(
                    pattern,
                    flags,
                    generateRegexpuOptions({ flags, pattern }),
                ),
            );
        }
        return new RegExp(pattern, flags);
    };
    // @ts-ignore
    global.RegExp.prototype = RegExp.prototype;
}
// eslint-disable-next-line no-extend-native
String.prototype.capitalizeFirstLetter = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const rootReducer = combineReducers({
    auth: authReducer,
    admin: adminReducer,
    researcher: researcherReducer
})
const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
));

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
)

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
