import * as actionTypes from './actionTypes';

export const addAdminSuccess = (adminList) => {
    return {
        type: actionTypes.ADD_ADMIN_SUCCESS,
        adminList: adminList
    }
}

export const addAdminFailure = () => {
    return {
        type: actionTypes.ADD_ADMIN_FAIL
    }
}

export const addAdminStart = () => {
    return {
        type: actionTypes.ADD_ADMIN_START
    }
}

let tempAdminListData = [{
    name: 'ABC',
    email: 'abc@gmail.com',
    phoneNumber: '9876543212',
    country: 'Subcription-B'
}]

export const addAdmin = (adminData) => {
    let prevArray = [...tempAdminListData]
    let newArray = prevArray.concat(adminData)
    return dispatch => {
        dispatch(addAdminSuccess(newArray))
    }
}

export const updateCurrentOrganisation = (currentOrganisation) => {
    return {
        type: actionTypes.UPDATE_CURRENT_ORGANISATION,
        payload: currentOrganisation
    }
}


