import axios from "axios";
import * as urls from './../../../Constants/URLs';
import * as roles from './../../../Constants/Roles';
import AlertService from "./../../Util/AlertDialogs/AlertService";
import { errorCodes } from "../../../Constants/ErrorCodes";
import { constraintNameMap } from "../../../Constants/Constraints";

// Brochure
export const getBrochure = () => {
    let brochureUrl = urls.BROCHURE_URL
    axios.get(brochureUrl).then((response) => {
        if (response.data && response.data.success) {
            let presignedUrl = response.data.data
            window.open(presignedUrl, "_blank")
        } else if (response?.data?.customErrorCode !== null) {
            AlertService.showAlert(errorCodes[response.data.customErrorCode], "failure");
        }
    }).then((_) => {
    })
}

// Help Doc
export const getHelpDoc = (role) => {
    let getHelpDocUrl = urls.HELP_DOC + '?role='
    if (role) {
        // if (role === roles.researcher) {
        //     getHelpDocUrl = getHelpDocUrl + 'USER'
        // } else {
        //     getHelpDocUrl = getHelpDocUrl + role
        // }
        getHelpDocUrl = getHelpDocUrl + role
    } else {
        getHelpDocUrl = getHelpDocUrl + roles.researcher
    }
    axios.get(getHelpDocUrl).then((response) => {
        if (response && response.data && response.data.success) {
            let presignedUrl = response.data.data
            window.open(presignedUrl, "_blank")
        } else if (response?.data?.customErrorCode) {
            AlertService.showAlert(errorCodes[response.data.customErrorCode], "failure");
        }
    }).then((_) => {
    })
}

// Password Generation
export const scorePassword = (pass) => {
    var score = 0;
    if (!pass)
        return score;

    // award every unique letter until 5 repetitions
    var letters = {};
    for (var i = 0; i < pass.length; i++) {
        letters[pass[i]] = (letters[pass[i]] || 0) + 1;
        score += 5.0 / letters[pass[i]];
    }

    // bonus points for mixing it up
    var variations = {
        digits: /\d/.test(pass),
        lower: /[a-z]/.test(pass),
        upper: /[A-Z]/.test(pass),
        nonWords: /\W/.test(pass),
    }

    var variationCount = 0;
    for (var check in variations) {
        variationCount += (variations[check] === true) ? 1 : 0;
    }
    score += (variationCount - 1) * 10;

    return parseInt(score);
}

export const passwordFit = (password) => {
    let criteria = {
        hasLength: false,
        hasNumber: false,
        hasAlphabet: false,
        hasSpecialChar: false
    }
    let hasLengthCriteria = password.length >= 8
    let numberPattern = /^(?=.*\d)/
    let alphabetPattern = /^(?=.*[a-z])(?=.*[A-Z])/
    let specialCharPattern = /^(?=.*[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/

    criteria.hasLength = hasLengthCriteria
    criteria.hasNumber = numberPattern.test(password)
    criteria.hasAlphabet = alphabetPattern.test(password)
    criteria.hasSpecialChar = specialCharPattern.test(password)

    return criteria
}

export const generate_password = () => {
    var length = Math.floor(Math.random() * (10 - 8)) + 8;
    var string = "abcdefghijklmnopqrstuvwxyz"; //to upper 
    var numeric = '0123456789';
    var punctuation = '!@#$%^&*';
    var password = "";
    var randomLowerCase = string.charAt(Math.ceil(numeric.length * Math.random() * Math.random()))
    var randomUpperCase = string.charAt(Math.ceil(numeric.length * Math.random())).toUpperCase()
    var character = randomUpperCase + randomLowerCase;
    var entity1, entity2, entity3, hold

    function removeDuplicateCharacters(string) {
        return string
            .split('')
            .filter(function (item, pos, self) {
                return self.indexOf(item) === pos;
            })
            .join('');
    }

    while (password.length < length) {
        entity1 = Math.ceil(string.length * Math.random() * Math.random());
        entity2 = Math.ceil(numeric.length * Math.random() * Math.random());
        entity3 = Math.ceil(punctuation.length * Math.random() * Math.random());
        hold = string.charAt(entity1);
        hold = Math.floor(Math.ceil(string.length) + 5) % 2 === 0 ? (hold.toUpperCase()) : (hold);
        character += numeric.charAt(entity2);
        character += punctuation.charAt(entity3);
        character += hold;
        password = character;
        password = removeDuplicateCharacters(password)
    }
    return password = password.substr(0, length)
}

// Input Field Validation
export function checkValidity(value, rules, inputIdentifier = null, inputConstraintState = null) {

    let isValid = true
    let errorMessage = null

    if (value === null || value === undefined || value === "") {
        isValid = false
        errorMessage = errorCodes.REQUIRED_INPUT_FIELD
        return {
            valid: isValid,
            error: errorMessage
        }
    }
    if (!rules) {
        isValid = true
        errorMessage = true
        return {
            valid: isValid,
            error: errorMessage
        };
    }
    if (rules.required) {
        isValid = value.trim() !== '' && isValid;
        if (isValid) {
            errorMessage = null
        } else {
            errorMessage = errorCodes.REQUIRED_INPUT_FIELD
        }
    }
    if (rules.isEmail) {
        const pattern = /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/;
        isValid = pattern.test(value) && isValid;
    }
    if (rules.isValidPassword) {
        //eslint-disable-next-line
        const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
        isValid = pattern.test(value) && isValid;
    }
    if (rules.minLength) {
        isValid = value.length >= rules.minLength && isValid;
    }
    if (rules.maxLength) {
        isValid = value.length <= rules.maxLength && isValid;
    }
    if (rules.isNumeric) {
        //eslint-disable-next-line
        const pattern = /^\-?[0-9]*(\.\d+)?$/; ///^\d+$/
        isValid = pattern.test(value) && isValid
        if (isValid) {
            errorMessage = null
        } else {
            errorMessage = errorCodes.ONLY_NUMERIC_INPUT
        }
    }
    if (rules.isPositiveNumeric) {
        const pattern = /^(\d*\.)?\d+$/; //^-?[0-9]\d*(\.\d+)?$
        isValid = pattern.test(value) && isValid
        if (isValid) {
            errorMessage = null
        } else {
            errorMessage = errorCodes.SHOULD_BE_POSITIVE
        }
    }
    if (rules.isPositiveHundred) {
        //eslint-disable-next-line
        const pattern = /^\-?[0-9]\d*(\.\d+)?$/;
        isValid = pattern.test(value) && (parseFloat(value) >= 0 && parseFloat(value) <= 100) && isValid
        if (isValid) {
            errorMessage = null
        } else {
            errorMessage = errorCodes.ONLY_POSITIVE_0_100

        }
    }
    if (rules.isAlphaNumeric) {
        const pattern = /^[a-zA-Z\d\-_.()\s]+$/;
        isValid = pattern.test(value) && isValid
        if (isValid) {
            errorMessage = null
        } else {
            errorMessage = errorCodes.ONLY_ALPHANUMERIC_INPUT
        }
    }
    if (rules.isInRange) {
        if (inputIdentifier && inputConstraintState && !isNaN(inputConstraintState[constraintNameMap[inputIdentifier]]?.lowerRange) && !isNaN(inputConstraintState[constraintNameMap[inputIdentifier]]?.upperRange) && !isNaN(parseFloat(value))) {
            isValid = (parseFloat(value) >= inputConstraintState[constraintNameMap[inputIdentifier]].lowerRange) && (parseFloat(value) <= inputConstraintState[constraintNameMap[inputIdentifier]].upperRange)
            if (isValid) {
                errorMessage = null
            } else {
                errorMessage = errorCodes.OUT_OF_RANGE
            }
        }
    }

    return {
        valid: isValid,
        error: errorMessage
    };
}