import React from 'react';
import classes from './Layout.module.css';
import Toolbar from './../Toolbar/Toolbar';
import Reminder from "../Auth/Reminder/Reminder"
function Layout(props) {

    return (
        <>
            <Toolbar />
            <main className={classes.content}>
                <Reminder />
                {props.children}
            </main>
        </>
    )
}

export default Layout;