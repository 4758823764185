import * as actionTypes from "../actions/actionTypes";
import * as routes from "../../Constants/Routes";

const initialState = {
  isAuthenticated: null,
  userRole: null,
  error: null,
  loading: false,
  activeUserId: null,
  orgId: null,
  authRedirectPath: routes.root,
  firstLogin: false,
  userName: null,
  profilePicChanged: 0,
  ipAdded: null,
  reminderMessage: ""
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return {
        ...state,
        loading: true,
        error: null
      };
    case actionTypes.UPDATE_PROFILE:
      return {
        ...state,
        userName: action.userName
      };
    case actionTypes.AUTH_SUCCESS:
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
        userRole: action.userRole,
        activeUserId: action.activeUserId,
        orgId: action.orgId,
        error: null,
        loading: false,
        firstLogin: action.firstLogin,
        userName: action.userName
      }
    case actionTypes.KEEP_LOGGED_IN:
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
        userRole: action.userRole,
        activeUserId: action.activeUserId,
        orgId: action.orgId,
        error: null,
        loading: false,
        userName: action.userName
      }
    case actionTypes.AUTH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case actionTypes.AUTH_LOGOUT:
      return {
        ...state,
        isAuthenticated: null,
        userRole: null,
        activeUserId: null,
        orgId: null,
        userName: null
      };
    case actionTypes.SET_AUTH_REDIRECT:
      return {
        ...state,
        authRedirectPath: action.path
      };
    case actionTypes.PROFILE_PIC_CHANGE:
      return {
        ...state,
        profilePicChanged: action.profilePicChanged
      };
    case actionTypes.IP_ADDED:
      return {
        ...state,
        ipAdded: action.ipAdded
      };
    case actionTypes.NULLIFY_ERROR:
      return {
        ...state,
        error: null
      }
    case actionTypes.SET_REMINDER:
      return {
        ...state,
        reminderMessage: action.reminderMessage
      }
    default:
      return state;
  }
};

export default reducer;
