/* eslint-disable default-case */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from 'react-router-dom';
// import NavigationItem from "./NavigationItem/NavigationItem";
import { Dropdown } from "react-bootstrap";
import classes from "./NavigationItems.module.scss";
import * as Routes from "../../../Constants/Routes";
import * as roles from "../../../Constants/Roles";
import ConfirmService from "../../Util/ConfirmDialogs/ConfirmService";
import { confirmMessages } from "../../../Constants/ErrorCodes";
import axios from "axios";
import * as urls from "../../../Constants/URLs";
import { getHelpDoc } from "./../../Auth/Utilities/Utilities";

function NavigationItems(props) {
  const isAuthenticated = useSelector(state => {
    return state.auth.isAuthenticated;
  });
  const role = useSelector(state => {
    return state.auth.userRole;
  });
  const activeUserId = useSelector(state => {
    return state.auth.activeUserId;
  });
  const profilePicChanged = useSelector(state => {
    return state.auth.profilePicChanged;
  });
  const userName = useSelector(state => {
    return state.auth.userName;
  });
  const organisationId = useSelector(state => {
    return state.auth.orgId;
  });
  let history = useHistory()
  let location = useLocation()

  const [image, setImage] = useState(null);

  useEffect(() => {
    if (isAuthenticated && profilePicChanged !== undefined) {
      axios.get(urls.PROFILE_PICTURE + "?userId=" + activeUserId)
        .then(res => {
          setImage(res.data.data.profilePicture)
        })
        .catch(e => { console.log(e) })
    }
  }, [profilePicChanged, isAuthenticated, activeUserId])
  function navigateToLocation(url) {

    if (location.pathname !== url) {
      if (url === Routes.admin) {
        history.push({
          pathname: Routes.admin,
          state: {
            organisationId: organisationId,
          }
        })
      }
      else {
        history.push(url)
      }
    }
  }


  async function logout() {
    let confirm = await ConfirmService.confirm(confirmMessages.logOut.question, confirmMessages.logOut.desc)
    if (confirm) {
      navigateToLocation(Routes.logout)
    }
  }

  function navigateToProfile(role) {
    if (location.pathname === Routes.profileTab) {
      history.replace({
        pathname: Routes.profileTab,
        state: {
          ...location.state,
          activeTab: 1
        }
      })
    } else {
      history.push({
        pathname: Routes.profileTab,
        state: {
          userId: activeUserId,
          userRole: role
        }
      })
    }
  }

  let dropdownMenu = null

  switch (role) {
    case roles.superAdmin:
      dropdownMenu = (
        <Dropdown.Menu >
          {isAuthenticated ? (
            <>
              {role === roles.superAdmin && location.pathname !== Routes.account ? (

                <>
                  <Dropdown.Item className="hide-lg" onClick={() => navigateToLocation(Routes.account)}>Account</Dropdown.Item>
                </>
              ) : null
              }
              {role === roles.admin && location.pathname !== Routes.admin ? (
                <>
                  <Dropdown.Item className="hide-lg" onClick={() => navigateToLocation(Routes.admin)}>Account</Dropdown.Item>
                </>
              ) : null
              }
              {role === roles.researcher && location.pathname !== Routes.overview ? (
                <>
                  <Dropdown.Item className="hide-lg" onClick={() => navigateToLocation(Routes.overview)}>New Blend</Dropdown.Item>
                </>
              ) : null
              }
            </>
          ) : null}
          <Dropdown.Item onSelect={() => navigateToProfile(roles.superAdmin)
          }>Profile</Dropdown.Item>
          <Dropdown.Item onSelect={() => getHelpDoc(role)} >Help <span className="float-right">F1</span></Dropdown.Item>
          <Dropdown.Item onSelect={logout} test-id="LOGOUT">Logout</Dropdown.Item>
        </Dropdown.Menu >
      )
      break
    case roles.globalAdmin:
      dropdownMenu = (
        <Dropdown.Menu >
          {isAuthenticated ? (
            <>
              {role === roles.globalAdmin && location.pathname !== Routes.account ? (

                <>
                  <Dropdown.Item className="hide-lg" onClick={() => navigateToLocation(Routes.account)}>Account</Dropdown.Item>
                </>
              ) : null
              }
              {role === roles.admin && location.pathname !== Routes.admin ? (
                <>
                  <Dropdown.Item className="hide-lg" onClick={() => navigateToLocation(Routes.admin)}>Account</Dropdown.Item>
                </>
              ) : null
              }
              {role === roles.researcher && location.pathname !== Routes.overview ? (
                <>
                  <Dropdown.Item className="hide-lg" onClick={() => navigateToLocation(Routes.overview)}>New Blend</Dropdown.Item>
                </>
              ) : null
              }
            </>
          ) : null}
          <Dropdown.Item onSelect={() => navigateToProfile(roles.globalAdmin)
          }>Profile</Dropdown.Item>
          <Dropdown.Item onSelect={() => getHelpDoc(role)} >Help <span className="float-right">F1</span></Dropdown.Item>
          <Dropdown.Item onSelect={logout} test-id="LOGOUT">Logout</Dropdown.Item>
        </Dropdown.Menu >
      )
      break
    case roles.admin:
      dropdownMenu = (
        <Dropdown.Menu>
          {isAuthenticated ? (
            <>
              {role === roles.superAdmin && location.pathname !== Routes.account ? (
                <>
                  <Dropdown.Item className="hide-lg" onClick={() => navigateToLocation(Routes.account)}>Account</Dropdown.Item>
                </>
              ) : null
              }
              {role === roles.admin && location.pathname !== Routes.admin ? (
                <>
                  <Dropdown.Item className="hide-lg" onClick={() => navigateToLocation(Routes.admin)}>Account</Dropdown.Item>
                </>
              ) : null
              }
              {role === roles.researcher && location.pathname !== Routes.overview ? (
                <>
                  <Dropdown.Item className="hide-lg" onClick={() => navigateToLocation(Routes.overview)}>New Blend</Dropdown.Item>
                </>
              ) : null
              }
            </>
          ) : null}
          <Dropdown.Item onSelect={() => navigateToProfile(roles.admin)
          }>Profile</Dropdown.Item>
          <Dropdown.Item onSelect={() => getHelpDoc(role)} >Help <span className="float-right">F1</span></Dropdown.Item>
          <Dropdown.Item onSelect={logout}>Logout</Dropdown.Item>
        </Dropdown.Menu>
      )
      break
    case roles.researcher:
      dropdownMenu = (
        <Dropdown.Menu>
          {isAuthenticated ? (
            <>
              {role === roles.superAdmin && location.pathname !== Routes.account ? (

                <>
                  <Dropdown.Item className="hide-lg" onClick={() => navigateToLocation(Routes.account)}>Home</Dropdown.Item>
                </>
              ) : null
              }
              {role === roles.admin && location.pathname !== Routes.admin ? (
                <>
                  <Dropdown.Item className="hide-lg" onClick={() => navigateToLocation(Routes.admin)}>Home</Dropdown.Item>
                </>
              ) : null
              }
              {role === roles.researcher && location.pathname !== Routes.overview ? (
                <>
                  <Dropdown.Item className="hide-lg" onClick={() => navigateToLocation(Routes.overview)}>Home</Dropdown.Item>
                </>
              ) : null
              }
            </>
          ) : null}
          <Dropdown.Item onSelect={() => navigateToProfile(roles.researcher)
          }>Profile</Dropdown.Item>
          <Dropdown.Item onSelect={() => getHelpDoc(role)}>Help <span className="float-right">F1</span></Dropdown.Item>
          <Dropdown.Item onSelect={logout}>Logout</Dropdown.Item>
        </Dropdown.Menu>
      )
      break
  }

  return (
    <ul className={classes.NavigationItems} test-id="NAVIGATION_ITEMS">
      {isAuthenticated ? (
        <>
          {(role === roles.superAdmin || role === roles.globalAdmin) && location.pathname !== Routes.account ? (
            <button className="btn-primary ml-20" type="button" onClick={() => navigateToLocation(Routes.account)}>Home</button>
          ) : null
          }
          {role === roles.admin && location.pathname !== Routes.admin ? (
            <>
              {/* <button className="btn-primary ml-20" type="button" onClick={() => navigateToLocation(Routes.overview)}>New Blend</button> */}
              <button className="btn-primary ml-20" type="button" onClick={() => navigateToLocation(Routes.admin)}>Home</button>
            </>
          ) : null
          }
          {role === roles.researcher && location.pathname !== Routes.overview ? (
            <>
              <button className="btn-primary ml-20" type="button" onClick={() => navigateToLocation(Routes.overview)}>Home</button>
            </>
          ) : null
          }

          <Dropdown className="header-actions-menu link-dropdown ml-20" test-id="DROPDOWN">
            <Dropdown.Toggle className="btn-icon" id="dropdown-basic-header">
              <div className="inline-block">
                <span className="user-icon">
                  {image !== null ? <div style={{ backgroundImage: "url('data:image/png;base64," + image + "')", backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center" }} className="profile-img"></div> : <i aria-hidden="true" className="far fa-user"></i>}
                </span>
              </div>
              <div className="inline-block">
                <span className="user-name capitalized">{userName}</span>
                <span className="user-role">{role === roles.researcher ? "USER" : role}</span>
              </div>
            </Dropdown.Toggle>
            {dropdownMenu}
          </Dropdown>

        </>
      ) : null}
    </ul>
  )
}

export default NavigationItems;
