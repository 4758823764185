import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../ReduxStore/actions";

function Reminder() {
    const dispatch = useDispatch()
    const reminderMessage = useSelector((state) => {
        return state.auth.reminderMessage
    })
    useEffect(() => {
        if (reminderMessage && reminderMessage.length > 0) {
            setTimeout(() => {
                if (reminderMessage.length > 0) {
                    dispatch(actions.setReminder(""))
                }
            }, 5000)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reminderMessage])

    function closeReminder() {
        if (reminderMessage?.length > 0) {
            dispatch(actions.setReminder(""))
        }
    }
    return (
        (reminderMessage && reminderMessage.length > 0) ?
            <div className="container" test-id={"REMINDER_VIEW"}>
                <div className="reminder-msg alert alert-danger">
                    <i aria-hidden="true" className="far fa-bell"></i>{reminderMessage}
                    <button className="float-right close" onClick={closeReminder} test-id={"CLOSE_BUTTON"}><i className="fas fa-times" aria-hidden="true"></i></button>
                </div>
            </div>
            :
            null
    )
}

export default Reminder;
