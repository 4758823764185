import React, { Component } from 'react'
import { render } from 'react-dom';
import successImage from '../../../Assets/Images/success.png'
import failureImage from '../../../Assets/Images/failure.png'
const variant = {
    success: "green",
    failure: "red",
    warning: "orange",
    info: "black"
}
class Alert extends Component {
    static create() {
        const containerElement = document.createElement('div');
        document.body.appendChild(containerElement);
        return render(<Alert />, containerElement);
    }


    constructor() {
        super();

        this.state = {
            isOpen: false,
            message: "Alert message",
            type: "info"
        };

        this.handleCancel = this.handleCancel.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
    }

    handleCancel() {
        this.setState({ isOpen: false });
    }

    handleConfirm() {
        this.setState({ isOpen: false });
    }

    showAlert(message, type) {
        this.setState({ isOpen: true, message: message, type: type });
        setTimeout(() => {
            this.setState({ isOpen: false })
        }, 4000);
    }
    render() {
        const { isOpen, message, type } = this.state;
        return (
            <FlashMessage isOpen={isOpen} message={message} color={variant[type]} type={type}></FlashMessage>
        )
    }
}

function FlashMessage(props) {
    return props.isOpen ? <div className="Alert fade-in"><div className="ml-30 mr-30 mt-10 mb-10">
        {props.type === 'success' ? <img src={successImage} alt="success"></img> : <img src={failureImage} alt="success"></img>}
        <span className="text-lg ml-10 alert-message" style={{ color: props.color }}>{props.message}</span></div></div > : null
}

export default Alert;
