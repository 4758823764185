import React, { Component } from 'react'
import { Modal, ModalBody, Col } from 'react-bootstrap'
import { render } from 'react-dom';
import { confirmMessages } from '../../../Constants/ErrorCodes';

let resolve;
class Confirm extends Component {
    static create() {
        const containerElement = document.createElement('div');
        document.body.appendChild(containerElement);
        return render(<Confirm />, containerElement);
    }

    constructor() {
        super();

        this.state = {
            isAlert: false,
            isOpen: false,
            title: "Are you sure?",
            message: "Please confirm your action",
            hideIcon: false,
        };

        this.handleCancel = this.handleCancel.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
    }

    handleCancel() {
        this.setState({ isOpen: false });
        resolve(false);

    }

    handleConfirm() {
        this.setState({ isOpen: false });
        resolve(true);
    }
    confirm(title, message, hideIcon) {
        this.setState({ isOpen: true, title: title, message: message, hideIcon: hideIcon });
        return new Promise((res) => {
            resolve = res;
        });
    }

    render() {
        const { isOpen, title, message, hideIcon } = this.state;
        return (
            <Modal show={isOpen} centered onHide={() => { }} test-id='modal'>
                <ModalBody> <div> {!hideIcon && <i aria-hidden="true" className="vertical-top far fa-question-circle text-xxxxl" ></i>}<span className="text-xl ml-10 mt-30 mr-30">{title ? title : confirmMessages.default.question}</span></div>
                    {/* <Modal.Title><span className="text-xl ml-15 mt-30">{title ? title : "Are you sure?"}</span></Modal.Title> */}
                    <div className={`mt-10 ml-35 dark-grey ${hideIcon ? 'ml-2' : ''}`}>
                        <span className="text-md">{message ? message : confirmMessages.default.desc}</span>
                    </div>
                    <div className="text-sm ml-35 d-none light-grey">
                        This is active customer with 20 users. Inactivation of a customer affects all users.
                    </div>
                    <Col md={12} lg={12} className="mt-30 text-center">
                        <button className="btn-primary" type="button" onClick={this.handleConfirm} test-id='confirmed'>Confirm</button>
                        <button className="btn-secondary ml-30" type="button" onClick={this.handleCancel} test-id='cancelled'>Cancel</button>
                    </Col>
                </ModalBody>
            </Modal>
        )
    }
}

export default Confirm;
