export const MODULE_TYPE = {
  MODULE1A: "MODULE1A",
  MODULE1B: "MODULE1B",
  MODULE2A: "MODULE2A",
  MODULE2B: "MODULE2B",
  MODULE3A: "MODULE3A",
  MODULE3B: "MODULE3B",
  MODULE4: "MODULE4",
  CRUDE_OIL_SAMPLE: "CRUDE_OIL_SAMPLE"
  // ,
  // MODULE3C: "MODULE3C",
};

export const MODULE_NAME = {
  MODULE1A_NAME: "Crude Oil Blend Compatibility Without Optimization",
  MODULE1B_NAME: "Crude Oil Blend Compatibility With Optimization",
  MODULE2A_NAME:
    "Crude Oil Blending for Bitumen Potential Without Optimization",
  MODULE2B_NAME: "Crude Oil Blending for Bitumen Potential With Optimization",
  MODULE3A_NAME: "Fuel Oil Blending With Optimization",
  MODULE3B_NAME: "Fuel Oil Blending Without Optimization",
  MODULE4_NAME: "Lube Oil Base Stock Potential",
  // MODULE3C_NAME: "Fuel Oil Blending With Optimization",
};

export const MODULES = [
  "1A (Crude Oil Blend Compatibility Without Optimization)",
  "1B (Crude Oil Blend Compatibility With Optimization)",
  "2A (Crude Oil Blending for Bitumen Potential Without Optimization)",
  "2B (Crude Oil Blending for Bitumen Potential With Optimization)",
  "3A (Fuel Oil Blending With Optimization)",
  "3B (Fuel Oil Blending Without Optimization)",
  "4 (Lube Oil Base Stock Model)",
  // "3C (Fuel Oil Blending Without Optimization)",
];

export const UNITS = {
  RUPPEES: "RUPEES",
  DOLLAR: "DOLLARS",
  CELSIUS: "CELSIUS",
  FEHRENHEIT: "FAHRENHEIT",
  WEIGHT: "WT",
  VOLUME: "VOL",
};

export const NOTATIONS = {
  RUPPEES_SYMBOL: "₹/tonne",
  DOLLAR_SYMBOL: "$/bbl",
  CELSIUS_SYMBOL: "\xB0C",
  FEHRENHEIT_SYMBOL: "\xB0F",
  VR: "VR",
  CUTTER: "CUTTER",
};
