export const root = "/";

export const resetPassword = "/reset-password-account";
// export const resetPassword1 = "/authentication/reset-password-account";
export const updatePassword = "/updatePassword";
export const forgotPassword = "/forgotPassword";
export const logout = "/logout";

export const coreModule = "/kmodel";
export const overview = "/overview";
export const module1A = "/1A";
export const module1B = "/1B";
export const module2A = "/2A";
export const module2B = "/2B";
export const module3A = "/3A";
export const module3B = "/3B";
export const module4 = "/4";
export const report = "/report";
export const crudeOilsAdminMaster = "/MasterInputComposition";
export const crudeOilsResearcherMaster = "/ResearcherMasterCrude";

export const account = "/account";
export const addAdmin = "/addAdmin";
export const addResearcher = "/addResearcher";
export const addSuperAdmin = "/addSuperAdmin";
export const addOrganization = "/addOrganization";

export const admin = "/admin";
export const addIp = "/addIp";
export const profile = "/profile";
export const editHits = "/editHits";
export const renewSubscription = "/renew";
export const editSubscription = "/editSubscription";
export const profileTab = "/viewprofile";
export const crudeOilDatabase = "/CrudeOilDatabase";
export const history = "/history";
export const error = "/error";