import * as actionTypes from '../actions/actionTypes';
import { MODULE_TYPE } from "../../Constants/Enumerations"

const initialState = {
    constraintState: {
        [MODULE_TYPE.MODULE1A]: {},
        [MODULE_TYPE.MODULE1B]: {},
        [MODULE_TYPE.MODULE2A]: {},
        [MODULE_TYPE.MODULE2B]: {},
        [MODULE_TYPE.MODULE3A]: {},
        [MODULE_TYPE.MODULE3B]: {},
        [MODULE_TYPE.MODULE4]: {}
    }
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_CONSTRAINTS:
            let payload = { ...action.payload }
            return {
                ...state,
                constraintState: {
                    ...state.constraintState,
                    ...payload
                }
            }
        default:
            return {
                ...state
            }
    }
}

export default reducer;